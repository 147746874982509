import { css } from '@emotion/core';

export default css({
    width: '100%',
    padding: '50px 30px',
    boxSizing: 'border-box',
    background: '#EEEEEE',
    '& > h3': {
        fontSize: 28,
        color: '#1f300d',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: 50
    },
    '& > div': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridGap: '1em',
        '& > a': {
            width: 300,
            justifySelf: 'center',
            transition: 'all 0.2s ease',
            '& .gatsby-image-wrapper': {
                width: '100%',
                borderRadius: '5px',
            },
            '&:hover': {
                transform: 'scale(1.02)'
            }
        }
    }
});
