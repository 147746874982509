import React from 'react';

const IconIG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        fill="#FFF"
        viewBox="0 0 38.06 38.06"
    >
        <g id="Capa_2" dataname="Capa 2">
            <g id="Capa_1-2" dataname="Capa 1">
                <path className="cls-1" d="M27.16,12.88a3,3,0,0,0-.78-1.2,3.12,3.12,0,0,0-1.2-.78,5.38,5.38,0,0,0-1.93-.36c-1.1,0-1.43-.06-4.22-.06s-3.12,0-4.22.06a5.38,5.38,0,0,0-1.93.36,3.45,3.45,0,0,0-2,2,5.38,5.38,0,0,0-.36,1.93c0,1.1-.06,1.43-.06,4.22s0,3.12.06,4.22a5.38,5.38,0,0,0,.36,1.93,3.12,3.12,0,0,0,.78,1.2,3,3,0,0,0,1.2.78,5.38,5.38,0,0,0,1.93.36c1.1.05,1.43.06,4.22.06s3.12,0,4.22-.06a5.38,5.38,0,0,0,1.93-.36,3.45,3.45,0,0,0,2-2,5.38,5.38,0,0,0,.36-1.93c.05-1.1.06-1.43.06-4.22s0-3.12-.06-4.22A5.38,5.38,0,0,0,27.16,12.88ZM19,24.39A5.36,5.36,0,1,1,24.39,19,5.36,5.36,0,0,1,19,24.39Zm5.57-9.68a1.25,1.25,0,1,1,1.25-1.25A1.25,1.25,0,0,1,24.6,14.71Z" />
                <path className="cls-1" d="M19,15.55A3.48,3.48,0,1,0,22.51,19,3.48,3.48,0,0,0,19,15.55Z" />
                <path className="cls-1" d="M19,0A19,19,0,0,0,0,19H0a19,19,0,0,0,38.06,0h0A19,19,0,0,0,19,0ZM29.4,23.33a8,8,0,0,1-.48,2.54,5.44,5.44,0,0,1-3.05,3.05,8,8,0,0,1-2.54.48c-1.11.05-1.47.06-4.3.06s-3.19,0-4.3-.06a7.83,7.83,0,0,1-2.53-.48,5.42,5.42,0,0,1-3.06-3.05,8,8,0,0,1-.48-2.54c-.05-1.11-.06-1.47-.06-4.3s0-3.19.06-4.3a7.83,7.83,0,0,1,.48-2.53A5.4,5.4,0,0,1,12.2,9.14a7.83,7.83,0,0,1,2.53-.48c1.11-.05,1.47-.06,4.3-.06s3.19,0,4.3.06a8,8,0,0,1,2.54.48,5.42,5.42,0,0,1,3.05,3.06,7.83,7.83,0,0,1,.48,2.53c.05,1.11.06,1.47.06,4.3S29.45,22.22,29.4,23.33Z" />
            </g>
        </g>
    </svg>
);

export default IconIG;
