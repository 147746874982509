import { css } from '@emotion/core';

export default css({
    width: '100%',
    height: 660,
    display: 'flex',
    flexDirection: 'column',
    '& > a': {
        width: '100%',
        height: '100%',
        textDecoration: 'none',
        cursor: 'pointer',
        transition: 'all ease 0.2s',
        '& > div': {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& p': {
                fontSize: 22,
                fontWeight: 500,
                textTransform: 'uppercase',
                color: '#FFF'
            }
        },
        '&:hover': {
            transform: 'scale(1.02)',
            zIndex: 1
        }
    },

    '@media only screen and (min-width: 1024px)': {
        height: 500,
        flexDirection: 'row',
        '& > a': {
            width: '25%',
        }
    }
});
