import React from 'react';

const IconLK = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        fill="#FFF"
        viewBox="0 0 38.06 38.06"
    >
        <g id="Capa_2" dataname="Capa 2">
            <g id="Capa_1-2" dataname="Capa 1">
                <path className="cls-1" d="M19,0A19,19,0,0,0,0,19H0a19,19,0,0,0,38.06,0h0A19,19,0,0,0,19,0ZM14.34,27.51H10.4V14.79h3.94Zm-2-14.39a2.35,2.35,0,1,1,2.33-2.35A2.34,2.34,0,0,1,12.35,13.12ZM29.1,27.51H25.18V20.83C25.18,19,24.48,18,23,18s-2.4,1.06-2.4,2.85v6.68H16.86V14.79h3.78V16.5a4.42,4.42,0,0,1,3.83-2.1c2.7,0,4.63,1.65,4.63,5.06Z" />
            </g>
        </g>
    </svg>
);

export default IconLK;
