import React from 'react';
import { Helmet } from 'react-helmet';

import MainHeader from '../components/home/MainHeader';
import MainFooter from '../components/home/MainFooter';
import NewsSection from '../components/home/NewsSection';
import Presentation from '../components/home/Presentation';
import MoneySection from '../components/home/MoneySection';
import BlocksSection from '../components/home/BlocksSection';
import EnergySection from '../components/home/EnergySection';
import MembersSection from '../components/home/MembersSection';
import CampaignsSection from '../components/home/CampaignsSection';

const Index = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE</title>
            <meta name="description" content="Juntos por la soberanía energética de México. La Comisión de Energía del CCE contribuye y colabora para el desarrollo y fortalecimiento del sector energético del país." />
            <meta name="google-site-verification" content="FTNSMKG_vDSyRg6B5qO08U4qvmDQO3cyJLiiA8YUOrs" />
        </Helmet>
        <main style={{ overflow: 'auto' }}>
            <MainHeader />
            <Presentation />
            <MembersSection />
            <MoneySection />
            <EnergySection />
            <BlocksSection />
            <CampaignsSection />
            <NewsSection />
            <MainFooter />
        </main>
    </>
);

export default Index;
