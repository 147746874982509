import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

import MenuIcon from '../../../helpers/icons/menu';
import styles from './styles';

const MainHeader = () => {
    const [state, setState] = useState({
        barVisible: true,
        menuVisible: false,
    });

    const {
        cce, cceenergia, cesp, cee
    } = useStaticQuery(graphql`
        query HeaderQuery {
            cce: file(relativePath: { eq: "cce_original.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 180
                    ) {
                        ...GatsbyImageSharpFluid,
                        originalName
                    }
                }
            }
            cceenergia: file(relativePath: { eq: "ccelogo.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 200
                    ) {
                    ...GatsbyImageSharpFluid,
                    originalName
                    }
                }
            }
            cesp: file(relativePath: { eq: "cespedes.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 100
                    ) {
                    ...GatsbyImageSharpFluid,
                    originalName
                    }
                }
            }
            cee: file(relativePath: { eq: "cee-logo.png" }) {
                childImageSharp {
                    fluid(
                        quality: 80,
                        maxWidth: 100,
                        duotone: {
                            highlight: "#FFFFFF",
                            shadow: "#ffffff"
                        }
                    ) {
                    ...GatsbyImageSharpFluid,
                    originalName
                    }
                }
            }
        }
    `);

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const v = currentScrollPos < 50;
        setState((prevState) => ({ ...prevState, barVisible: v }));
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header css={styles} className="mainheader-cce">
            <div
                className={
                    state.barVisible ? 'mainheader-cce-logo' : 'mainheader-cce-logo mainheader-cce-logo--hidden'
                }
            >
                <Img
                    className="ccelogo"
                    fluid={cceenergia.childImageSharp.fluid}
                    alt={cceenergia.childImageSharp.fluid.originalName}
                />
                <Img
                    className="cce"
                    fluid={cce.childImageSharp.fluid}
                    alt={cce.childImageSharp.fluid.originalName}
                />
            </div>
            <nav
                className={
                    state.barVisible ? 'mainheader-cce-nav' : 'mainheader-cce-nav mainheader-cce-nav--short'
                }
            >
                <Link rel="nofollow noopener noreferrer" to="/" className="home-icon">Inicio</Link>
                <Link rel="nofollow noopener noreferrer" to="/quienessomos">Quiénes somos</Link>
                <Link rel="nofollow noopener noreferrer" to="/miembros">Miembros</Link>
                <Link rel="nofollow noopener noreferrer" to="/lineastrabajo">Líneas de Trabajo</Link>
                <Link rel="nofollow noopener noreferrer" to="/eventos">Eventos</Link>
                <Link rel="nofollow noopener noreferrer" to="/infografias">Infografías</Link>
                <Link rel="nofollow noopener noreferrer" to="/noticias">Noticias/Prensa</Link>
                <Link rel="nofollow noopener noreferrer" to="/resumenes-regulatorios">Resúmenes Regulatorios</Link>
                {/* <Link rel="nofollow noopener noreferrer" to="/intranet">Intranet</Link> */}
            </nav>
            <div
                className={
                    state.barVisible ? 'mainheader-cce-button' : 'mainheader-cce-button mainheader-cce-button--short'
                }
            >
                <button
                    type="button"
                    onClick={() => setState((prevState) => ({ ...prevState, menuVisible: true }))}
                >
                    <MenuIcon />
                </button>
                <nav
                    className={
                        state.menuVisible ? 'mainheader-cce-mininav' : 'mainheader-cce-mininav mainheader-cce-mininav--closed'
                    }
                >
                    <button
                        type="button"
                        onClick={() => setState((prev) => ({ ...prev, menuVisible: false }))}
                    >
                        x
                    </button>
                    <Link rel="nofollow noopener noreferrer" to="/">Inicio</Link>
                    <Link rel="nofollow noopener noreferrer" to="/quienessomos">Quiénes somos</Link>
                    <Link rel="nofollow noopener noreferrer" to="/miembros">Miembros</Link>
                    <Link rel="nofollow noopener noreferrer" to="/lineastrabajo">Líneas de Trabajo</Link>
                    <Link rel="nofollow noopener noreferrer" to="/eventos">Eventos</Link>
                    <Link rel="nofollow noopener noreferrer" to="/infografias">Infografías</Link>
                    <Link rel="nofollow noopener noreferrer" to="/noticias">Noticias/Prensa</Link>
                    {/* <Link rel="nofollow noopener noreferrer" to="/intranet">Intranet</Link> */}
                    <div className="footer-links">
                        <h4>Enlaces de interés</h4>
                        <a
                            className="host-link"
                            href="https://www.cce.org.mx/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <Img
                                className="host-image contain"
                                fluid={cce.childImageSharp.fluid}
                                alt={cce.childImageSharp.fluid.originalName}
                            />
                            <p>CCE</p>
                        </a>
                        <a
                            className="host-link"
                            href="http://www.cespedes.org.mx/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <Img
                                className="host-image"
                                fluid={cesp.childImageSharp.fluid}
                                alt={cesp.childImageSharp.fluid.originalName}
                            />
                            <p>CESPEDES</p>
                        </a>
                        <a
                            className="host-link"
                            href="https://cee.colmex.mx/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <Img
                                className="host-image"
                                fluid={cee.childImageSharp.fluid}
                                alt={cee.childImageSharp.fluid.originalName}
                            />
                            <p>CEE</p>
                        </a>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default MainHeader;
