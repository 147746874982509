import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './styles';

const EnergySection = () => {
    const {
        oilImage, electrImage, gasImage, petrImage
    } = useStaticQuery(
        graphql`
            query HomeEnergyQuery {
                oilImage: file(relativePath: { eq: "home/oil.png" }) {
                    childImageSharp {
                        fluid(quality: 80, maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                electrImage: file(relativePath: { eq: "home/electricity.png" }) {
                    childImageSharp {
                        fluid(quality: 80, maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                gasImage: file(relativePath: { eq: "home/gas.png" }) {
                    childImageSharp {
                        fluid(quality: 80, maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                petrImage: file(relativePath: { eq: "home/news-industry.png" }) {
                    childImageSharp {
                        fluid(quality: 80, maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }  
        `
    );
    return (
        <section css={styles} className="energysection">
            <Link
                to="/lineastrabajo?wk=hydro"
                rel="nofollow noopener noreferrer"
            >
                <BackgroundImage
                    fluid={oilImage.childImageSharp.fluid}
                >
                    <p>Hidrocarburos</p>
                </BackgroundImage>
            </Link>
            <Link
                to="/lineastrabajo?wk=petroil"
                rel="nofollow noopener noreferrer"
            >
                <BackgroundImage
                    fluid={petrImage.childImageSharp.fluid}
                >
                    <p>Petrolíferos</p>
                </BackgroundImage>
            </Link>
            <Link
                to="/lineastrabajo?wk=gas"
                rel="nofollow noopener noreferrer"
            >
                <BackgroundImage
                    fluid={gasImage.childImageSharp.fluid}
                >
                    <p>Gas natural</p>
                </BackgroundImage>
            </Link>
            <Link
                to="/lineastrabajo?wk=electric"
                rel="nofollow noopener noreferrer"
            >
                <BackgroundImage
                    fluid={electrImage.childImageSharp.fluid}
                >
                    <p>Electricidad</p>
                </BackgroundImage>
            </Link>
        </section>
    );
};

export default EnergySection;
