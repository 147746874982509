import React from 'react';
import Microlink from '@microlink/react';
import { useMediaQuery } from 'react-responsive';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './styles';

const NewsSection = () => {
    const isMaxWidth = useMediaQuery({ query: '(max-width: 1023px)' });
    const { news, newsBackground } = useStaticQuery(graphql`
        query NewsSectionQuery {
            news: allStrapiNoticias(sort: { order: DESC, fields: updated_at }) {
                edges {
                    node {
                        id
                        url
                        source
                    }
                }
            }
            newsBackground: file(relativePath: { eq: "home/news.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const { edges } = news;
    const classEdges = isMaxWidth ? 'news-slider-column' : 'news-slider-row';
    const filtEdges = edges.filter((edge) => edge.node.source !== null);
    return (
        <section css={styles} className="news-section">
            <BackgroundImage
                className="news-background"
                fluid={newsBackground.childImageSharp.fluid}
            >
                <h3>Noticias</h3>
                <div className={classEdges}>
                    {filtEdges.map((edge, i) => {
                        if (i < 3) {
                            return (
                                <Microlink
                                    key={edge.node.id}
                                    url={edge.node.url}
                                    size={isMaxWidth ? 'normal' : 'large'}
                                />
                            );
                        }
                        return null;
                    })}
                </div>
            </BackgroundImage>
        </section>
    );
};

export default NewsSection;
