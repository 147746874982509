import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import { isToday } from 'date-fns';
import upperFirst from 'lodash/upperFirst';

import fetchXHR from '../../../helpers/http';
import styles from './styles';

const LOCAL = 'cce-energia';

const getDateFromStr = (str) => {
    const arr = str.split('/');
    return new Date(
        parseInt(arr[2], 10),
        parseInt(arr[1], 10) - 1,
        parseInt(arr[0], 10)
    );
};

const MoneySection = () => {
    const [state, setState] = useState({
        fecha: new Date(),
        fix: 0.00,
        nivel: 0.00,
        // mezcla: 0.00
    });
    useEffect(() => {
        const localJson = localStorage.getItem(LOCAL);
        const localItem = localJson ? JSON.parse(localJson) : null;
        const strFecha = localItem ? localItem.fecha : '17/11/1995';
        const dateItem = getDateFromStr(strFecha);
        if (isToday(dateItem)) {
            setState({
                fecha: state.fecha,
                fix: localItem.fix,
                nivel: localItem.nivel,
                // mezcla: localItem.mezcla
            });
        } else {
            fetchXHR(process.env.GATSBY_DOLAR_SERVICE, 'GET', {
                token: process.env.GATSBY_BMX_TOKEN,
            })
                .then(({ json: { bmx } }) => {
                    const nivelSerie = bmx.series.find((s) => s.idSerie === 'SF43786');
                    const nivelFix = bmx.series.find((s) => s.idSerie === 'SF43718');
                    // const nivelMezcla = bmx.series.find((s) => s.idSerie === 'SI744');
                    const toLocal = {
                        fecha: nivelSerie.datos[0].fecha,
                        nivel: nivelSerie.datos[0].dato,
                        fix: nivelFix.datos[0].dato,
                        // mezcla: nivelMezcla.datos[0].dato
                    };
                    localStorage.setItem(LOCAL, JSON.stringify(toLocal));
                    setState({ ...toLocal, fecha: state.fecha });
                })
                .catch((error) => console.error('Error:', error));
        }
    }, []);

    return (
        <section css={styles} className="moneysection">
            <div className="moneysection-icon">
                <img alt="money" src="/images/home/money.png" />
            </div>
            <h3>{upperFirst(format(state.fecha, 'PPPP', { locale: es }))}</h3>
            <div className="moneysection-type">
                <h4>Tipo de cambio peso/dólar</h4>
                <div>
                    <div>
                        <p>FIX: {state.fix}</p>
                    </div>
                    <div>
                        <p>NIVEL: {state.nivel}</p>
                    </div>
                </div>
            </div>
            {/*
            <div className="moneysection-type">
                <h4>Precio de la Mezcla Mexicana de petróleo</h4>
                <div>
                    <div style={{ width: '100%' }}>
                        <p>{state.mezcla ? state.mezcla : 'N/E'}</p>
                        <p>{state.mezcla && state.mezcla !== 'N/E' ? 'Dólares por barril' : ''}</p>
                    </div>
                </div>
            </div>
            */}
        </section>
    );
};

export default MoneySection;
