import { css } from '@emotion/core';

export default css({
    width: '100%',
    height: 900,
    '& .presentation-image': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    '& .presentation-text': {
        width: '80%',
        margin: '0px auto',
        position: 'relative',
        '& h1': {
            color: '#FFF',
            fontSize: 36,
            textAlign: 'center',
            fontWeight: 300,
            marginBottom: 30,
            '& b': {
                fontWeight: 500
            }
        },
        '& h2': {
            width: 'fit-content',
            color: '#455b2f',
            fontSize: 16,
            margin: '0px auto 10px',
            backgroundColor: '#FFF',
            position: 'relative',
            display: 'none',
        },
        '& h3': {
            color: '#fff',
            fontSize: 18,
            textAlign: 'center',
            lineHeight: '1.5em',
            width: 'fit-content',
            margin: '0px auto',
            padding: 10,
            boxSizing: 'border-box',
        },
        '& .presentation-paragraph': {
            '&-1': {
                padding: '8px 8px 8px 25px',
                '&::before': {
                    content: '""',
                    display: 'block',
                    width: 30,
                    height: 50,
                    transform: 'skew(-30deg)',
                    background: 'linear-gradient(90deg, rgba(245,51,64,1) 0%, rgba(210,0,39,1) 100%)',
                    position: 'absolute',
                    top: '-9px',
                    left: '-10px'
                }
            },
            '&-2': {
                padding: '8px 25px 8px 8px',
                '&::after': {
                    content: '""',
                    display: 'block',
                    width: 30,
                    height: 50,
                    transform: 'skew(-30deg)',
                    background: 'linear-gradient(90deg, rgba(245,51,64,1) 0%, rgba(210,0,39,1) 100%)',
                    position: 'absolute',
                    top: '-7px',
                    right: '-10px'
                }
            }
        },
        '& .social-media': {
            position: 'absolute',
            top: '140%',
            right: '50%',
            transform: 'translate(50%, -50%)',
            display: 'flex',
            '& > a': {
                display: 'block',
                margin: '0px 20px',
                transition: 'all 0.2s ease',
                '&:hover': {
                    transform: 'scale(1.1)'
                },
                textAlign: 'center'
            },
            '& a.text-link': {
                color: '#ffffff',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '100%',
                textDecoration: 'none',
                textAlign: 'center'
            }
        }
    },

    '@media only screen and (min-width: 768px)': {
        '& .presentation-text': {
            '& h2': {
                display: 'block'
            },
            '& h3': {
                display: 'none'
            }
        }
    },

    '@media only screen and (min-width: 1024px)': {
        '& .presentation-text': {
            ' h1': {
                maxWidth: 410,
                fontSize: 52,
                textAlign: 'left',
            },
            '& h2': {
                fontSize: 18,
                margin: '0px 0px 10px 0px',
            },
            '& .social-media': {
                top: '50%',
                right: '0px',
                transform: 'translate(-50%, -50%)',
                display: 'block',
                '& > a': {
                    margin: '20px 0px',
                }
            }
        }
    }
});
