import React from 'react';
import Slider from 'react-slick';

import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './styles';

const NextArrow = (params) => {
    const { className, style, onClick } = params;
    return (
        <div // eslint-disable-line
            className={className}
            onClick={onClick}
            style={{
                ...style,
                display: 'block',
                width: 40,
                height: 70,
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: 'url("/images/white-arrow-right.png")',
            }}
        />
    );
};

const PrevArrow = (params) => {
    const { className, style, onClick } = params;
    return (
        <div // eslint-disable-line
            className={className}
            onClick={onClick}
            style={{
                ...style,
                display: 'block',
                width: 40,
                height: 70,
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: 'url("/images/white-arrow-left.png")',
            }}
        />
    );
};

const settings = {
    autoplay: true,
    adaptiveHeight: true,
    fade: true,
    dots: false,
    infinite: true,
    draggable: false,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
};

const MembersSection = () => {
    const {
        membersBackground, assocs, companies, organisms
    } = useStaticQuery(graphql`
        query HomeMembersQuery {
            membersBackground: file(relativePath: { eq: "home/pais.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 540) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            assocs: allFile(
                filter: {
                    extension: { regex: "/(jpeg|jpg|gif|png)/" },
                    relativeDirectory: { eq: "members/associations"},
                },
                sort: {
                    fields: childImageSharp___sizes___originalName, order: ASC
                }
            ){
                edges {
                    node {
                        childImageSharp {
                            fluid(
                                quality: 80,
                                maxWidth: 120,
                            ) {
                                ...GatsbyImageSharpFluid
                                originalName
                            }
                            id
                        }
                    }
                }
            }
            companies: allFile(
                filter: {
                    extension: {regex: "/(jpeg|jpg|gif|png)/"},
                    relativeDirectory: {eq: "members/companies"},
                },
                sort: {
                    fields: childImageSharp___sizes___originalName, order: ASC
                }
            ){
                edges {
                    node {
                        childImageSharp {
                            fluid(
                                quality: 80,
                                maxWidth: 70,
                            ) {
                                ...GatsbyImageSharpFluid
                                originalName
                            }
                            id
                        }
                    }
                }
            }
            organisms: allFile(
                filter: {
                    extension: {regex: "/(jpeg|jpg|gif|png)/"},
                    relativeDirectory: {eq: "members/organisms"}
                },
                sort: {
                    fields: childImageSharp___sizes___originalName, order: ASC
                }
            ){
                edges {
                    node {
                        childImageSharp {
                            fluid(
                                quality: 80,
                                maxWidth: 120,
                            ) {
                                ...GatsbyImageSharpFluid
                                originalName
                            }
                            id
                        }
                    }
                }
            }
        }
    `);
    return (
        <section css={styles} className="memberssection">
            <BackgroundImage
                className="members-background"
                fluid={membersBackground.childImageSharp.fluid}
            >
                <div className="members-title">
                    <h3>
                        La Comisión de Energía del CCE cuenta con
                        más de<b> 70 miembros</b> que representan
                        a más de <b>200 empresas</b>
                    </h3>
                    <Link to="/miembros">
                        Conoce más
                    </Link>
                </div>
                <Slider {...settings}>
                    <div className="slick-section">
                        <h4>Organismos empresariales</h4>
                        <div className="slick-grid">
                            {organisms.edges.map((edge) => (
                                <div key={edge.node.childImageSharp.id} className="carousel-image">
                                    <Img
                                        fluid={edge.node.childImageSharp.fluid}
                                        alt={edge.node.childImageSharp.fluid.originalName}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="slick-section">
                        <h4>Asociaciones</h4>
                        <div className="slick-grid">
                            {assocs.edges.map((edge) => (
                                <div key={edge.node.childImageSharp.id} className="carousel-image">
                                    <Img
                                        fluid={edge.node.childImageSharp.fluid}
                                        alt={edge.node.childImageSharp.fluid.originalName}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="slick-section">
                        <h4>Empresas</h4>
                        <div className="slick-minigrid">
                            {companies.edges.map((edge) => (
                                <div key={edge.node.childImageSharp.id} className="carousel-image">
                                    <Img
                                        fluid={edge.node.childImageSharp.fluid}
                                        alt={edge.node.childImageSharp.fluid.originalName}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Slider>
            </BackgroundImage>
        </section>
    );
};

export default MembersSection;
