import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import IconTW from '../../../helpers/icons/twitter';
import IconFB from '../../../helpers/icons/facebook';
import IconLK from '../../../helpers/icons/linkedin';
import IconIG from '../../../helpers/icons/instagram';
import styles from './styles';

const Presentation = () => {
    const data = useStaticQuery(graphql`
        query HomePresentationQuery {
            file(relativePath: { eq: "home/presentation.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
            allStrapiBoletines(sort: {order: DESC, fields: updated_at}) {
                nodes {
                    link
                    title
                    updated_at
                }
            }
        }
    `);

    const boletin = data.allStrapiBoletines.nodes.length > 0 ? data.allStrapiBoletines.nodes[0] : false;

    return (
        <section css={styles} className="presentation">
            <BackgroundImage
                className="presentation-image"
                fluid={data.file.childImageSharp.fluid}
            >
                <div className="presentation-text">
                    <h1>JUNTOS POR LA <b>SOBERANÍA ENERGÉTICA</b> DE MÉXICO</h1>
                    <h2 className="presentation-paragraph-1">
                        La Comisión de Energía del CCE contribuye y colabora para el desarrollo
                    </h2>
                    <h2 className="presentation-paragraph-2">
                        y fortalecimiento del sector energético. #EnergíaEsDesarrollo
                    </h2>
                    <h3>
                        La Comisión de Energía del CCE contribuye y colabora para el desarrollo
                        y fortalecimiento del sector energético. #EnergíaContigo
                    </h3>
                    <div className="social-media">
                        {boletin != false
                            && (
                                <a
                                    href={boletin.link}
                                    rel="nofollow noopener noreferrer"
                                    target="_blank"
                                    className="text-link"
                                >
                                    Newsletter
                                </a>
                            )}
                        <a
                            href="https://twitter.com/cceenergia"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <IconTW />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/cceenergia/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <IconLK />
                        </a>
                        <a
                            href="https://www.facebook.com/cceenergia/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <IconFB />
                        </a>
                        <a
                            href="https://www.instagram.com/cceenergia/"
                            rel="nofollow noopener noreferrer"
                            target="_blank"
                        >
                            <IconIG />
                        </a>

                    </div>
                </div>
            </BackgroundImage>
        </section>
    );
};

export default Presentation;
