import { css } from '@emotion/core';

export default css({
    width: '100%',
    height: 660,
    '& .news-background': {
        width: '100%',
        height: '100%',
        padding: '50px 0px',
        '& h3': {
            fontSize: 26,
            color: '#FFF',
            textAlign: 'center',
            textTransform: 'uppercase',
            marginBottom: 80
        },
        '& .news-slider-row': {
            display: 'flex',
            justifyContent: 'center',
            '& .microlink_card': {
                width: 370,
                margin: '0px 15px',
                border: 'none',
                boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
                '&__media_image': {
                    width: '100%',
                    height: 220,
                    flex: 'none',
                    marginBottom: 20
                },
                '&__content': {
                    padding: '0px 20px',
                    flex: '0 0 115px'
                }
            }
        },
        '& .news-slider-column': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .microlink_card': {
                width: '90%',
                margin: '15px 0px',
            }
        },
    },
    '@media only screen and (min-width: 1024px)': {
        '& .news-background': {
            '& h3': {
                fontSize: 28,
            }
        }
    }
});
