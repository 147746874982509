import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './styles';

const BlocksSection = () => {
    const {
        lineasImg, engraneBtn
    } = useStaticQuery(graphql`
        query HomeBlocksQuery {
            lineasImg: file(relativePath: { eq: "home/lineastrabajo.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 450) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
            engraneBtn: file(relativePath: { eq: "home/engrane.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 100, maxHeight: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <section css={styles} className="blockssection">
            <div className="blockssection-box">
                <div className="blockssection-text">
                    <div>
                        <h3 id="block-title-left">Líneas de trabajo</h3>
                    </div>
                    <p id="block-paragraph-left">
                        Sabemos que el sector energético es diverso. Por ello en la Comisión de
                        Energía del CCE enfocamos nuestros esfuerzos en varios rubros de trabajo.
                        Conócelos.
                    </p>
                </div>
                <BackgroundImage
                    className="blockssection-image"
                    fluid={lineasImg.childImageSharp.fluid}
                />
                <Link
                    to="/lineastrabajo"
                    className="block-button"
                    rel="nofollow noopener noreferrer"
                >
                    <Img
                        alt="engraneBtn"
                        fluid={engraneBtn.childImageSharp.fluid}
                    />
                </Link>
            </div>
            {/**
             <div className="blockssection-box">
                <BackgroundImage
                    id="event-image"
                    className="blockssection-image"
                    fluid={eventosImg.childImageSharp.fluid}
                />
                <div
                    id="event-text"
                    className="blockssection-text"
                >
                    <div>
                        <h3 id="block-title-right">Eventos</h3>
                    </div>
                    <p id="block-paragraph-right">
                        Entérate de los nuevos eventos que la Comisión de Energía del CCE realiza
                        en toda la República Mexicana. ¡No te pierdas de ninguno!
                    </p>
                </div>
                <Link
                    to="/eventos"
                    className="block-button"
                    rel="nofollow noopener noreferrer"
                >
                    <Img
                        alt="eventosBtn"
                        fluid={eventosBtn.childImageSharp.fluid}
                    />
                </Link>
            </div>
            */}
        </section>
    );
};

export default BlocksSection;
