import { css } from '@emotion/core';

export default css({
    width: '100%',
    background: '#FFF',
    padding: '50px 0px',
    '& .moneysection-icon': {
        width: 69,
        height: 69,
        margin: '0px auto 20px',
        '& img': {
            width: '100%',
            height: '100%'
        }
    },
    '& h3': {
        width: '100%',
        color: '#1f300d',
        fontSize: 22,
        textAlign: 'center',
        margin: '0px 0px 35px',
    },
    '& .moneysection-type': {
        maxWidth: 600,
        padding: '0px 10px',
        boxSizing: 'border-box',
        margin: '0px auto 30px',
        '& h4': {
            fontSize: 22,
            color: '#959595',
            textAlign: 'center',
            textTransform: 'uppercase',
            marginBottom: 10
        },
        '& > div': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            '& > div': {
                width: '100%',
                padding: 15,
                border: '1px dashed #f5333f',
                borderTopLeftRadius: '30px',
                borderBottomLeftRadius: '30px',
                borderTopRightRadius: '30px',
                borderBottomRightRadius: '30px',
                marginBottom: 5,
            },
            '& p': {
                fontSize: 16,
                fontWeight: 300,
                textAlign: 'center',
                color: '#959595',
                textTransform: 'uppercase',
                marginBottom: 0,
                '&:first-of-type': {
                    fontSize: 22,
                    fontWeight: 400,
                    marginBottom: 5,
                }
            }
        }
    },
    '@media only screen and (min-width: 768px)': {
        '& .moneysection-type': {
            '& > div': {
                flexDirection: 'row',
                '& > div': {
                    width: '49%',
                    borderTopLeftRadius: '30px',
                    borderBottomLeftRadius: '30px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    marginBottom: 0,
                    '&:last-child': {
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '30px',
                        borderBottomRightRadius: '30px',
                    }
                },
            }
        }
    }
});
