import { css } from '@emotion/core';

export default css({
    width: '100%',
    '& .blockssection-box': {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    '& .blockssection-text': {
        width: '100%',
        height: 440,
        '& > div': {
            width: '100%',
            height: 130,
            position: 'relative',
            '& h3': {
                fontSize: 26,
                color: '#959595',
                textAlign: 'center',
                textTransform: 'uppercase',
                padding: 15,
                boxSizing: 'border-box',
                position: 'absolute',
                top: '50px'
            }
        },
        '& p': {
            fontSize: 18,
            color: '#1f300d',
            margin: '20px 60px 0px',
            lineHeight: '30px'
        }
    },
    '& #event-image': {
        order: 2
    },
    '& #event-text': {
        order: 1
    },
    '& #block-title-left': {
        left: '0px',
        width: 320,
        borderRight: '1px dashed #f5333f',
        borderTop: '1px dashed #f5333f',
        borderBottom: '1px dashed #f5333f',
        borderTopRightRadius: '30px',
        borderBottomRightRadius: '30px'
    },
    '& #block-title-right': {
        right: '0px',
        width: 320,
        padding: '15px 15px 15px 115px',
        borderLeft: '1px dashed #f5333f',
        borderTop: '1px dashed #f5333f',
        borderBottom: '1px dashed #f5333f',
        borderTopLeftRadius: '30px',
        borderBottomLeftRadius: '30px'
    },
    '& #block-paragraph-left': {
        textAlign: 'left'
    },
    '& #block-paragraph-right': {
        textAlign: 'right'
    },
    '& .blockssection-image': {
        width: '100%',
        height: 450
    },
    '& .block-button': {
        width: 100,
        height: 100,
        textDecoration: 'none',
        position: 'absolute',
        top: '49%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        zIndex: 1,
        '&:hover': {
            width: 110,
            height: 110
        },
        '&:focus': {
            outline: 0
        }
    },
    '@media only screen and (min-width: 450px)': {
        '& #block-title-left': {
            width: 419
        },
        '& #block-title-right': {
            width: 354
        }
    },
    '@media only screen and (min-width: 768px)': {
        '& .blockssection-box': {
            flexDirection: 'row'
        },
        '& .blockssection-text': {
            width: '50%',
            height: 450,
            '& > div': {
                height: 160,
                '& h3': {
                    fontSize: 28
                }
            }
        },
        '& .blockssection-image': {
            width: '50%',
            height: 450
        },
        '& .block-button': {
            top: '50%',
        },
        '& #event-image': {
            order: 1
        },
        '& #event-text': {
            order: 2
        },
    }
});
