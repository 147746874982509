import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';

import styles from './styles';

const campaignsQuery = graphql`
  query CampaignsQuery {
    content: allStrapiCampanas(sort: { order: ASC, fields: updated_at }) {
      edges {
        node {
            id
            campaignName
            campaignImage {
              localFile {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    sizes
                  }
                }
              }
            }
        }
      }
    }
  }
`;

const CampaignsCmp = () => {
    const { content } = useStaticQuery(campaignsQuery);
    const { edges } = content;
    return (
        <section css={styles}>
            <h3>Infografías</h3>
            <div>
                {edges.map((edge) => {
                    const n = edge.node.campaignName.split(' ').join('-').toLowerCase();
                    return (
                        <Link
                            key={edge.node.id}
                            to={`/infografias?name=${n}`}
                            rel="nofollow noopener noreferrer"
                        >
                            <Img
                                fluid={edge.node.campaignImage.localFile.childImageSharp.fluid}
                                alt={edge.node.campaignName}
                            />
                        </Link>
                    );
                })}
            </div>
        </section>
    );
};

export default CampaignsCmp;
