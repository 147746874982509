import { css } from '@emotion/core';

export default css({
    width: '100%',
    '& .members-background': {
        width: '100%',
        padding: '50px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& .members-title': {
            width: '100%',
            '& > h3': {
                fontSize: 24,
                color: '#FFF',
                textAlign: 'center',
                marginBottom: 20,
                lineHeight: '50px',
                padding: '0px 10px',
                boxSizing: 'border-box',
                '& > b': {
                    fontSize: 30
                }
            },
            '& > a': {
                width: 'fit-content',
                padding: '20px 40px',
                textDecoration: 'none',
                fontSize: 20,
                color: '#f5333f',
                border: 'none',
                borderRadius: 10,
                backgroundColor: '#FFF',
                display: 'none',
            },
        },
        '& .slick-slider': {
            width: '72%',
            maxWidth: '590px',
            borderRadius: '5px',
            margin: '0px auto',
            background: 'rgba(255, 255, 255, 0.7)',
            boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
        },
        '& .slick-prev': {
            left: '-45px',
            '&:before': {
                display: 'none'
            },
        },
        '& .slick-next': {
            right: '-45px',
            '&:before': {
                display: 'none'
            },
        },
        '& .slick-section': {
            paddingTop: 15,
            '& > h4': {
                fontSize: 22,
                color: '#2f555e',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginBottom: 40,
            },
            '& .slick-grid': {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                gridGap: '0.2em',
                padding: '0px 10px',
                '& .carousel-image': {
                    width: 120,
                    height: 80,
                    margin: '0px auto',
                }
            },
            '& .slick-minigrid': {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(70px, 1fr))',
                gridGap: '0.5em',
                padding: '0px 20px',
                '& .carousel-image': {
                    width: 70,
                    height: 60,
                    margin: '0px auto',
                }
            },
        },
    },
    '@media only screen and (min-width: 768px)': {
        '& .members-background': {
            flexDirection: 'row',
            '& .members-title': {
                marginRight: 85,
                width: '30%',
                '& > h3': {
                    fontSize: 28,
                    textAlign: 'left',
                    '& > b': {
                        fontSize: 36
                    }
                },
                '& > a': {
                    display: 'block',
                }
            },
            '& .slick-slider': {
                width: '44%',
                margin: 0,
            }
        }
    }
});
