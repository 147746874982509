import isEmpty from 'lodash/isEmpty';

export default function FetchXHR(url, method, data) {
    const urlGet = (urlG, params) => {
        if (!isEmpty(params)) {
            const paramsUrl = Object.keys(params)
                .map((key) => `${key}=${params[key]}`)
                .join('&');
            return `${urlG}?${paramsUrl}`;
        }
        return urlG;
    };

    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        const urlToSend = method === 'GET' ? urlGet(url, data) : url;
        request.open(method, urlToSend, true);
        request.setRequestHeader('Accept', 'application/JSON');
        const dataToSend = method !== 'GET' ? JSON.stringify(data) : null;
        request.send(dataToSend);
        request.onreadystatechange = function handleResponse() {
            if ((this.responseText !== undefined && this.responseText !== ''
                && this.responseText !== null && this.responseText !== ' '
                && this.responseText[this.responseText.length - 1] === '}'
                && this.readyState === 4 && this.status === 200)
                || (this.readyState === 4 && this.status === 400)) {
                resolve({
                    json: JSON.parse(this.responseText),
                    status: {
                        info: this.statusText,
                        code: this.status
                    }
                });
            }
        };
        request.onerror = function handleError(error) {
            reject(error);
        };
    });
}
