import { css } from '@emotion/core';

export default css({
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    zIndex: 5,
    '& .mainheader-cce-logo': {
        width: '100%',
        padding: '0px 15px',
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'all 0.1s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        visibility: 'visible',
        '& .ccelogo': {
            width: 100
        },
        '& .cce': {
            width: 125,
            height: 50,
            '& img': {
                backgroundSize: 'contain',
                objectFit: 'contain !important'
            },
            '& picture': {
                backgroundSize: 'contain',
                objectFit: 'contain !important'
            }
        },
        '&--hidden': {
            height: 0,
            margin: 0,
            visibility: 'hidden'
        }
    },
    '& .mainheader-cce-button': {
        width: '100%',
        height: 66,
        padding: '0px 15px',
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'all 0.5s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: '1px solid rgba(255, 255, 255, 0.43)',
        zIndex: 5,
        '& > button': {
            width: 50,
            height: 50,
            background: 'transparent',
            border: 'none',
            cursor: 'pointer'
        }
    },
    '& .mainheader-cce-nav': {
        width: '100%',
        height: 66,
        padding: '0px 60px',
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'all 0.5s ease',
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px solid rgba(255, 255, 255, 0.43)',
        '& a': {
            fontSize: 14,
            color: '#FFF',
            textTransform: 'uppercase',
            textDecoration: 'none',
            marginRight: 15
        },
        '& .home-icon': {
            background: 'url("/images/home.png")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '32px',
            width: '32px',
            textIndent: '-999999px'
        }
    },
    '& .mainheader-cce-button--short, .mainheader-cce-nav--short': {
        background: '#272727',
        borderTop: '0px',
    },
    '& .mainheader-cce-mininav': {
        width: 325,
        height: '100vh',
        padding: '20px 10px',
        boxSizing: 'border-box',
        background: '#272727',
        position: 'fixed',
        top: '0px',
        right: '0px',
        transition: 'right 0.5s ease',
        overflow: 'auto',
        '& > button': {
            width: 40,
            height: 40,
            background: 'transparent',
            border: 'none',
            fontSize: 28,
            color: '#FFF',
            marginBottom: 15,
            cursor: 'pointer'
        },
        '& a': {
            fontSize: 14,
            color: '#FFF',
            textTransform: 'uppercase',
            textDecoration: 'none',
            textAlign: 'right',
            padding: 15,
            marginBottom: 15,
            width: '100%',
            display: 'block',
            boxSizing: 'border-box',
            background: 'transparent',
            transition: 'background 0.2s ease',
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.1)',
            }
        },
        '&--closed': {
            right: '-325px',
        },
        '& .footer-links': {
            borderTop: '1px solid rgba(255,255,255,0.2)',
            paddingTop: 20,
            '& > h4': {
                color: 'rgba(255,255,255,0.2)',
                textAlign: 'right',
                marginBottom: 20,
                padding: 15,
                boxSizing: 'border-box',
            }
        },
        '& .host-link': {
            width: 210,
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textDecoration: 'none',
        },
        '& .host-image': {
            width: 100,
        },
    },

    '@media only screen and (min-width: 1024px)': {
        '& .mainheader-cce-logo': {
            padding: '0px 60px',
            '& .ccelogo': {
                width: 150,
            },
            '& .cce': {
                width: 175,
                height: 100
            }
        },
        '& .mainheader-cce-button': {
            display: 'none'
        },
        '& .mainheader-cce-nav': {
            display: 'flex'
        }
    }
});
