import React from 'react';

const IconTW = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        fill="#FFF"
        viewBox="0 0 38.06 38.06"
    >
        <g id="Capa_2" dataname="Capa 2">
            <g id="Capa_1-2" dataname="Capa 1">
                <path className="cls-1" d="M19,0A19,19,0,0,0,0,19H0a19,19,0,0,0,38.06,0h0A19,19,0,0,0,19,0Zm8.06,14.89A11.83,11.83,0,0,1,8.89,25.4a8.37,8.37,0,0,0,6.16-1.72,4.18,4.18,0,0,1-3.89-2.89A4.22,4.22,0,0,0,13,20.72a4.16,4.16,0,0,1-3.33-4.13,4.26,4.26,0,0,0,1.88.52,4.18,4.18,0,0,1-1.29-5.56,11.79,11.79,0,0,0,8.58,4.35A4.17,4.17,0,0,1,26,12.1a8.13,8.13,0,0,0,2.64-1,4.17,4.17,0,0,1-1.83,2.31,8.39,8.39,0,0,0,2.39-.66A8.18,8.18,0,0,1,27.09,14.89Z" />
            </g>
        </g>
    </svg>
);

export default IconTW;
