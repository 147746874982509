import React from 'react';

const IconFB = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38.06 38.06"
        fill="#FFF"
    >
        <g id="Capa_2" dataname="Capa 2">
            <g id="Capa_1-2" dataname="Capa 1">
                <path className="cls-1" d="M19,0h0A19,19,0,0,0,0,19H0a19,19,0,0,0,19,19h0a19,19,0,0,0,19-19h0A19,19,0,0,0,19,0Zm5.31,11.56H21.83c-.88,0-1.07.36-1.07,1.28v2.21h3.58L24,18.93H20.76v11.6H16.13V19H13.72V15.05h2.41V12c0-2.9,1.55-4.42,5-4.42h3.21Z" />
            </g>
        </g>
    </svg>
);

export default IconFB;
